import { ApplicationModeEnum, ApplicationState, ApplicationStateSchema, ApplicationStates } from "./ApplicationStateSchema";
import {
    ActionTypeEnum,
    AgreeToConsentAnsweredAction,
    ApplicationProcessedAction,
    BeneficiarySelectedAction,
    ClubIdentifiedAction,
    CoverageSelectedAction,
    HealthQuestionsAnsweredAction,
    LandingPageVisitedAction,
    OfferFoundAction,
    PaymentTokenReceivedAction,
    PersonalInfoVerifiedAction,
    QuotesFoundAction,
    ReducerActionSchema,
    SecondaryAddresseeSubmittedAction,
} from "types/ActionTypes";
import { invitationCodeRegex } from "constants/validations";

function toSpouseApplicationId(memberApplicationId: string | null | undefined) {
    return `${memberApplicationId!.substring(0, 1)}1${memberApplicationId!.substring(2, 10)}`;
}

function reducer<TState extends ApplicationStates>(state: TState, action: any): TState {
    const parseResults = ReducerActionSchema.safeParse(action);

    if (!parseResults.success) {
        console.error(parseResults.error);
        return state;
    }

    switch (action.type) {
        case ActionTypeEnum.enum.LANDING_PAGE_VISITED:
            return handleLandingPageVisited(state, action);
        case ActionTypeEnum.enum.OFFER_FOUND:
            return handleOfferFound(state, action);
        case ActionTypeEnum.enum.DIRECTTERM_QUOTES_FOUND:
            return handleQuoteFound(state, action);
        case ActionTypeEnum.enum.LOYALTY_QUOTES_FOUND:
            return handleQuoteFound(state, action);
        case ActionTypeEnum.enum.CLUB_IDENTIFIED:
            return handleClubIdentified(state, action);
        case ActionTypeEnum.enum.COVERAGE_SELECTED:
            return handleCoverageSelected(state, action);
        case ActionTypeEnum.enum.PERSONAL_INFO_VERIFIED:
            return handlePersonalInformationVerified(state, action);
        case ActionTypeEnum.enum.BENEFICIARY_SELECTED:
            return handleBeneficiarySelected(state, action);
        case ActionTypeEnum.enum.SECONDARY_ADDRESSEE_SUBMITTED:
            return handleSecondaryAddresseeSubmitted(state, action);
        case ActionTypeEnum.enum.HEALTH_QUESTIONS_ANSWERED:
            return handleHealthQuestionsAnswered(state, action);
        case ActionTypeEnum.enum.PAYMENT_TOKEN_RECEIVED:
            return handlePaymentTokenReceived(state, action);
        case ActionTypeEnum.enum.AGREE_TO_CONSENT_ANSWERED:
            return handleAgreeToConsentAnswered(state, action);
        case ActionTypeEnum.enum.APPLICATION_PROCESSED:
            return handleApplicationProcessed(state, action);
        case ActionTypeEnum.enum.APPLICATION_COMPLETED:
            return handleApplicationCompleted(state, action);
        default:
            return state;
    }
}

function handleApplicationCompleted<TState extends ApplicationStates>(
    state: TState,
    action: ApplicationProcessedAction
): TState {
    return { ...state };
}

function handleApplicationProcessed<TState extends ApplicationStates>(
    state: TState,
    action: ApplicationProcessedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            policyStatus: action.policyStatus,
        },
    };
}

function handleAgreeToConsentAnswered<TState extends ApplicationStates>(
    state: TState,
    action: AgreeToConsentAnsweredAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            agreeToConsent: action.agreeToConsent,
        },
    };
}

function handlePaymentTokenReceived<TState extends ApplicationStates>(
    state: TState,
    action: PaymentTokenReceivedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            paymentToken: action.paymentToken,
        },
    };
}

function handleHealthQuestionsAnswered<TState extends ApplicationStates>(
    state: TState,
    action: HealthQuestionsAnsweredAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            hasSevereMedicalConditions: action.hasSevereMedicalConditions,
            hadDiagnosticTesting: action.hadDiagnosticTesting,
        },
    };
}

function handleSecondaryAddresseeSubmitted<TState extends ApplicationStates>(
    state: TState,
    action: SecondaryAddresseeSubmittedAction
): TState {
    return {
        ...state,
        application: {
            ...state.application,
            designatedSecondaryAddressee: action.designatedSecondaryAddressee,
            secondaryAddressee: {
                firstName: action.firstName,
                lastName: action.lastName,
                phone: action.phone,
                addressLine1: action.addressLine1,
                addressLine2: action.addressLine2,
                city: action.city,
                state: action.state,
                zipCode: action.zipCode,
            },
        },
    };
}

function handleBeneficiarySelected<TState extends ApplicationStates>(state: TState, action: BeneficiarySelectedAction): TState {
    return {
        ...state,
        application: {
            ...state.application,
            beneficiary: {
                firstName: action.firstName,
                middleInitial: action.middleInitial,
                lastName: action.lastName,
                relationship: action.relationship,
                percentage: action.percentage,
            },
        },
    };
}

function handlePersonalInformationVerified<TState extends ApplicationStates>(
    state: TState,
    action: PersonalInfoVerifiedAction
): TState {
    const spouseValues =
        state.application!.applicantType !== "spouse"
            ? {}
            : { firstName: action.firstName, middleInitial: action.middleInitial, lastName: action.lastName };

    return {
        ...state,
        application: {
            ...state.application,
            ...spouseValues,

            policyNumber: action.policyNumber,
            hannoverId: action.hannoverId,
            addressLine1: action.addressLine1,
            addressLine2: action.addressLine2,
            city: action.city,
            state: action.state,
            phone: action.phone,
            phoneType: action.phoneType,
            email: action.email,
            height: action.height,
            weight: action.weight,
            willReplacePolicy: action.willReplacePolicy,
            policyToReplace: action.policyToReplace,
        },
    };
}

function handleCoverageSelected<TState extends ApplicationStates>(state: TState, action: CoverageSelectedAction): TState {
    // Ensure selectedCoverageAmount is one of the available coverageOptions
    return {
        ...state,
        application: {
            ...state.application,
            coverageAmounts: action.coverageAmounts,
            selectedCoverageTier: action.selectedCoverageTier,
            selectedCoverageType: action.selectedCoverageType,
            selectedCoverageAmount: action.selectedCoverageAmount,
            selectedCoveragePremium: action.selectedCoveragePremium,
        },
    };
}

function handleClubIdentified<TState extends ApplicationState>(state: TState, action: ClubIdentifiedAction): TState {
    return {
        ...state,
        clubSpecificData: {
            ...state.clubSpecificData,
            clubCode: action.clubCode,
            planCode: action.planCode,
        },
    };
}

function handleQuoteFound<TState extends ApplicationStates>(state: TState, action: QuotesFoundAction): TState {
    const memberDefaultValues =
        action.applicantType !== "member"
            ? {}
            : {
                  firstName: state.firstName!,
                  lastName: state.lastName!,
              };

    const coverageOptions =
        action.type === ActionTypeEnum.Enum.DIRECTTERM_QUOTES_FOUND
            ? state
                  .offer!.map((offer) => parseInt(offer, 10)) // Keep results in the order presented in offer array
                  .map((offer) => action.coverageOptions.find((option) => option.coverageAmount === offer))
                  .filter((option) => !!option)
            : action.coverageOptions;

    const directTermSpecificState = "membershipLevel" in action ? { membershipLevel: action.membershipLevel } : {};
    const loyaltyTravelAccidentSpecificApplicationState =
        "membershipLevel" in action ? {} : { gender: action.gender, hasUsedNicotineLastYear: action.hasUsedNicotineLastYear };

    const applicantSpecificApplicationData =
        state.application && action.applicantType === state.application.applicantType
            ? state.application // We preserve whole application for same applicant
            : {}; // Otherwise we preserve the previously set club territory information

    return {
        ...state,
        ...directTermSpecificState,
        application: {
            applicantType: action.applicantType,
            // Preserves previous application state from previous and subsequent steps if applicant types remain
            // the same, or first time on step
            // ...(state.application && action.applicantType === state.application.applicantType ? state.application : {}),
            ...applicantSpecificApplicationData,

            // Defaults (prefills) values such as name for the member who the offer was addressed to
            ...memberDefaultValues,
            coverageOptions: coverageOptions,

            // Clear out previously selected coverage
            selectedCoverageAmount: undefined,
            selectedCoveragePremium: undefined,
            selectedCoverageTier: undefined,
            selectedCoverageType: undefined,

            zipCode: action.zipCode,
            state: action.state,

            ...loyaltyTravelAccidentSpecificApplicationState,
            dateOfBirth: action.dateOfBirth,
            email: action.email,

            applicationID:
                action.applicantType === "member" ? state.invitationCode! : toSpouseApplicationId(state.invitationCode),
            paymentFrequency: "Monthly",
        },
    };
}

function handleOfferFound<TState extends ApplicationStates>(state: TState, action: OfferFoundAction): TState {
    if (action.invitationCode === state.invitationCode) {
        // Don't modify the state, we have already accounted for the user entering this invitation code
        return state;
    }
    return {
        ...state,

        invitationCode: action.invitationCode,
        firstName: action.firstName,
        // LATER: Set middleInitial here once it is made available from /findOffer endpoint
        lastName: action.lastName,
        planCode: action.planCode,
        offer: action.offer,
        memberOfferAvailable: action.memberOfferAvailable,
        spouseOfferAvailable: action.spouseOfferAvailable,
        keyCode: action.keyCode,
        aaaMemberNumber: action.aaaMemberNumber,
        membershipLength: action.membershipLength,
        memberSince: action.memberSince,
        gender: action.gender,

        // FUTURE: clear quote and application data
        application: {},
    };
}

export function handleLandingPageVisited<TState extends ApplicationStates>(
    state: TState,
    action: LandingPageVisitedAction
): TState {
    const { url, params } = action;

    const clubCode = params.get("cc");
    const leadSource = params.get("sc");
    const campaignCode = params.get("cmp");
    const uid = params.get("uid");

    const timeout = params.get("timeout");
    const promptBeforeIdle = params.get("promptBeforeIdle");

    const match = new URL(url).hostname.match(/(?<SUBDOMAIN>[^.]+)\.((localhost)|([^.]+.[^.]+))/);

    const subdomain = params.get("subdomain") || match?.groups?.SUBDOMAIN || "directterm";

    const invitationCode = typeof uid === "undefined" ? state.invitationCode : invitationCodeRegex.exec(uid)?.[0];

    return {
        ...state,
        applicationMode: subdomain as ApplicationModeEnum,
        invitationCode: invitationCode,

        session: {
            ...state.session,

            ...(timeout && promptBeforeIdle
                ? { timeout: parseInt(timeout), promptBeforeIdle: parseInt(promptBeforeIdle) }
                : {}),
        },

        campaign: {
            ...state.campaign,
            clubCode: clubCode ?? state?.campaign?.clubCode,
            leadSource: leadSource ?? state?.campaign?.leadSource,
            campaignCode: campaignCode ?? state?.campaign?.campaignCode,
            campaignUrl: url ?? state.campaign?.campaignUrl,
        },
    };
}

function reducerWrapper<TState extends ApplicationStates>(state: TState, action: any): TState {
    logStateErrors(state);
    const newState = reducer(state, action);
    logStateErrors(newState);
    return newState;
}

function logStateErrors(state: ApplicationState) {
    try {
        const parseResults = ApplicationStateSchema.deepPartial().safeParse(state);
        if (!parseResults.success) {
            parseResults.error.issues.forEach((e) => console.warn(`${e.path.join(".")}: ${e.message}`));
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export { reducerWrapper as reducer };
