import React, { forwardRef } from "react";
import { InputText } from "components/inputs";
import { FieldError } from "react-hook-form";

type Props = {
    hasError?: FieldError;
} & React.ComponentPropsWithoutRef<"input">;
export const InputZipCode = forwardRef<HTMLInputElement, Props>(
    ({ onChange, onKeyDown, type, inputMode, maxLength, hasError, ...rest }, ref) => (
        // type, inputMode, maxLength are all included intentionally in order to exclude them from ...rest
        <InputText
            type="text"
            inputMode="numeric"
            maxLength={5}
            hasError={hasError}
            ref={ref}
            {...rest}
            onKeyDown={(e) => {
                if (
                    ["Delete", "Backspace", "Tab", "Esc", "Enter", "Home", "End", "ArrowLeft", "ArrowRight"].includes(e.key) ||
                    (e.ctrlKey && (e.key === "a" || e.key === "c" || e.key === "v" || e.key === "x")) ||
                    /^[0-9]$/.test(e.key)
                ) {
                    if (typeof onKeyDown === "function") {
                        onKeyDown(e);
                    }
                    return;
                }
                e.preventDefault();
            }}
            onChange={(e) => {
                const newValue = e.currentTarget.value.replace(/[^0-9]/g, "");
                if (newValue !== e.currentTarget.value) {
                    e.currentTarget.value = newValue;
                }

                if (typeof onChange === "function") {
                    onChange(e);
                }
            }}
        />
    )
);
