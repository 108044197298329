const RELATIONSHIP_SELECT_OPTIONS = [
    { value: "Spouse", label: "Spouse" },
    { value: "Child", label: "Child" },
    { value: "Sibling", label: "Sibling" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Grandchild", label: "Grandchild" },
    { value: "Fiancee", label: "Fiancee" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "DomesticPartner", label: "Domestic Partner" },
    { value: "Aunt", label: "Aunt" },
    { value: "Uncle", label: "Uncle" },
    { value: "Cousin", label: "Cousin" },
    { value: "BusinessPartner", label: "Business partner" },
];

export default RELATIONSHIP_SELECT_OPTIONS;
