import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";
import { parseFormatted10DigitPhone, format10DigitNanpaPhone } from "utils/phone";

type StyledInputProps = {
    hasError?: FieldError | boolean;
};

const StyledInput = styled.input<StyledInputProps>`
    height: 38px;
    font-size: inherit;
    padding: 0 6px;

    border-style: solid;
    border-radius: 3px;
    border-width: ${(props) => (props.hasError ? "2px" : "1px")};
    border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey5)};

    &:focus-visible {
        outline: none;
        border-color: ${(props) => (props.hasError ? props.theme.colors.red : props.theme.colors.grey7)};
    }
`;

type Props = StyledInputProps &
    React.ComponentPropsWithoutRef<"input"> & {
        value?: string;
    };

export const InputPhone = forwardRef<HTMLInputElement, Props>(function InputPhone(props, ref) {
    const [unformattedPhone, setUnformattedPhone] = useState(props.value);
    const { id = "phone", name = "phone", value, onChange, onBlur, disabled = false, hasError = false, ...rest } = props;

    const innerRef = useRef<HTMLInputElement>(null);

    // Expose a custom handle which simulates a native input
    useImperativeHandle(
        ref,
        () => {
            return {
                ...innerRef.current,
                get value() {
                    return parseFormatted10DigitPhone(innerRef?.current?.value || "");
                },
                set value(theValue) {
                    if (!innerRef.current) {
                        throw new Error("The component was in an invalid state in order to set value.");
                    }
                    const valueOrEmpty = theValue || "";
                    innerRef.current.value = format10DigitNanpaPhone(valueOrEmpty);
                    setUnformattedPhone(parseFormatted10DigitPhone(valueOrEmpty));

                    // Raise a change event so that transformed value is picked up
                    innerRef.current.dispatchEvent(new Event("change"));
                },
            } as HTMLInputElement;
        },
        []
    );

    return (
        <StyledInput
            type="tel"
            id={id}
            name={name}
            placeholder=""
            maxLength={17}
            disabled={disabled}
            hasError={hasError}
            autoComplete="tel-national"
            value={format10DigitNanpaPhone(unformattedPhone)}
            onChange={(e) => {
                const parsedValue = parseFormatted10DigitPhone(e.target.value);
                setUnformattedPhone(parsedValue);
                if (onChange) {
                    onChange(e);
                }
            }}
            onBlur={onBlur}
            ref={innerRef}
            {...rest}
        />
    );
});

// NOTE: This component exists mainly as a workaround for bug DTO-293 where we had infinite rendering issues using
// InputPhone in that page specifically. The preference is to have a single uncontrolled component that exposes a ref
// but allows for component and behavior reuse without tying directly to react-form-hook so both components exist for now
// until we can get to the bottom of the rendering issue.
export const InputPhoneControlled = function InputPhoneControlled(props: Props) {
    const { id, name, value, onChange, onBlur, disabled = false, hasError = false, ...rest } = props;

    return (
        <StyledInput
            {...rest}
            type="tel"
            id={id}
            name={name}
            placeholder=""
            maxLength={17}
            disabled={disabled}
            hasError={hasError}
            autoComplete="tel-national"
            value={format10DigitNanpaPhone(parseFormatted10DigitPhone(value || ""))}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};
