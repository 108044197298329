import { v4 as generateUniqueId } from "uuid";
import { ApplicationModeEnum, ApplicationStates } from "./ApplicationStateSchema";

/**
 * Creates the initial state.
 * @returns - A single atom representing the accumulated
 * information entered and kept throughout the form process
 */
export function createInitialState<TState extends ApplicationStates>(
    additionalState: Partial<Readonly<ApplicationStates>> = {}
): TState {
    const { search, href } = window.location;

    const match = new URL(href).hostname.match(/(?<SUBDOMAIN>[^.]+)\.((localhost)|([^.]+.[^.]+))/);
    const subdomain =
        new URLSearchParams(search).get("subdomain") ||
        match?.groups?.SUBDOMAIN ||
        additionalState.applicationMode ||
        "directterm";

    return {
        memberOfferAvailable: false,
        spouseOfferAvailable: false,

        application: {},

        session: {
            sessionID: generateUniqueId(),
        },

        // Allow setting the session timeout values through query strings for QA
        // https://aaalife.atlassian.net/browse/DTO-138

        ...(additionalState || {}),

        applicationMode: subdomain as ApplicationModeEnum,
    } as TState;
}
