import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "layout";
import Button from "components/inputs/button/Button";
import PersonalInfoForm from "features/forms/personal-info-form/PersonalInfoForm";
import callCheckIdentity from "./callCheckIdentity";

const DirectTermPersonalInfoContainer = ({ content, applicationData, dispatch, onError, handleClickBack, handleClickNext }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (submittedData) => {
        try {
            setIsLoading(true);

            const { policyNumber, hannoverId } = applicationData;
            const verifiedPersonalInfoData = await callCheckIdentity("directterm", submittedData, policyNumber, hannoverId);

            dispatch({
                type: "PERSONAL_INFO_VERIFIED",
                policyNumber: verifiedPersonalInfoData.policyNumber,
                hannoverId: verifiedPersonalInfoData.hannoverId,

                firstName: verifiedPersonalInfoData.firstName,
                middleInitial: verifiedPersonalInfoData.middleInitial,
                lastName: verifiedPersonalInfoData.lastName,

                addressLine1: verifiedPersonalInfoData.addressLine1,
                addressLine2: verifiedPersonalInfoData.addressLine2,
                city: verifiedPersonalInfoData.city,
                state: verifiedPersonalInfoData.state,
                phone: verifiedPersonalInfoData.phone,
                phoneType: verifiedPersonalInfoData.phoneType,
                email: verifiedPersonalInfoData.email,
                height: verifiedPersonalInfoData.height,
                weight: verifiedPersonalInfoData.weight,
                willReplacePolicy: verifiedPersonalInfoData.willReplacePolicy,
                policyToReplace: verifiedPersonalInfoData.policyToReplace,
            });

            setIsLoading(false);

            handleClickNext();
        } catch (error) {
            setIsLoading(false);

            console.error(error);
            if (onError) {
                onError(error);
            }
        }
    };

    const initialValues = {
        applicantType: applicationData.applicantType,
        coverageAmount: applicationData.selectedCoverageAmount,
        firstName: applicationData.firstName,
        middleInitial: applicationData.middleInitial,
        lastName: applicationData.lastName,
        dateOfBirth: applicationData.dateOfBirth,
        gender: applicationData.gender,
        zipCode: applicationData.zipCode,
        addressLine1: applicationData.addressLine1,
        addressLine2: applicationData.addressLine2,
        city: applicationData.city,
        state: applicationData.state,
        phone: applicationData.phone,
        phoneType: applicationData.phoneType,
        email: applicationData.email,
        height: applicationData.height,
        weight: applicationData.weight,
        willReplacePolicy: applicationData.willReplacePolicy,
        policyToReplace: applicationData.policyToReplace,
    };

    return (
        <>
            <PersonalInfoForm
                content={content}
                onSubmit={handleSubmit}
                onError={onError}
                onNavigate={navigate}
                initialValues={initialValues}
                showHeightSection={true}
                showWeightSection={true}
                showWillReplacePolicyQuestion={true}
            />
            <ButtonGroup>
                <Button label={content.previousLabel} onClick={handleClickBack} />
                <Button label={content.nextLabel} type="submit" color="primary" form="personal-info-form" loading={isLoading} />
            </ButtonGroup>
        </>
    );
};

export default DirectTermPersonalInfoContainer;
