import styled from "styled-components";
import { ErrorMessage } from "components/inputs";
import Popover from "components/popover/Popover";
import { FieldErrors, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { invitationCodeRegex, invitationCodeFirstCharRegex, invitationCodeSecondCharRegex } from "constants/validations";

import { FieldError } from "components/FieldError";
import { logErrors } from "utils/logErrors";
import { FindOfferResponseBody, findOffer } from "services/onlineApplicationService";
import InputInvitationCode from "components/inputs/invitation-code/InputInvitationCode";
import { setRawHtml } from "content/setRawHtml";

const Container = styled.div`
    flex-grow: 1;
    display: flex;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SentenceLabel = styled.label`
    margin: 0;
`;

const Label = styled.label`
    padding-bottom: ${(props) => props.theme.spacing.padding.xs};
    font-weight: bold;
`;

const HelpTextContainer = styled.div`
    display: flex;
    margin-bottom: 50px;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.theme.spacing.padding.small};
`;

const StyledInputInvitationCode = styled(InputInvitationCode)`
    font-size: 18px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    font-family: Roboto Mono, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    max-width: 460px;
`;

const InvitationImage = styled.img`
    width: 133px;
    height: 220px;
    border-bottom: ${(props) => props.theme.colors.grey4} 2px solid;
    border-right: ${(props) => props.theme.colors.grey4} 3px solid;
    padding: 0;
`;

const InvitationTooltip = styled.p`
    padding-left: ${(props) => props.theme.spacing.padding.xs};
    margin: 0;
`;

const PaddedContainer = styled(Container)`
    padding-bottom: 8px;
    > Label {
        padding-bottom: 0;
    }
`;

export type OnSubmitArgs = { invitationCode: string; offer: FindOfferResponseBody };

/** The props shape for the InvitationCodeForm component */
type InvitationCodeFormProps = {
    content: import("content/contentSchemas").WelcomeContent;
    invitationCode?: string;
    onSubmit: (data: OnSubmitArgs) => void;
    onError?: (validationErrors: FieldErrors<InvitationCodeFormFieldValues>) => void;
};

type InvitationCodeFormFieldValues = {
    invitationCode: string;
};

/**
 * @returns A InvitationCodeForm component
 */
const InvitationCodeForm = ({ content, invitationCode: initialInvitationCode, onSubmit, onError }: InvitationCodeFormProps) => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            invitationCode: initialInvitationCode,
        } as InvitationCodeFormFieldValues,
    });

    const onSubmitWithFindOffer: SubmitHandler<InvitationCodeFormFieldValues> = async (data) => {
        try {
            const invitationCode = data.invitationCode.toUpperCase();
            const offer = await findOffer(invitationCode);

            onSubmit({ invitationCode, offer });
        } catch (error) {
            console.error(error);
            setError("root.invitationCode", {
                type: "invitationNotFound",
                message: content.invitationNotFoundErrorMessage,
            });
        }
    };

    const onErrorWithLogging: SubmitErrorHandler<InvitationCodeFormFieldValues> = (validationErrors) => {
        logErrors(validationErrors);
        if (onError) {
            onError(validationErrors);
        }
    };

    const validateInvitationCode = async (value: string) => {
        const firstChar = value[0];
        const secondChar = value[1];

        if (!invitationCodeFirstCharRegex.test(firstChar)) {
            return content.invitationCodeFirstCharErrorMessageHtml;
        }

        if (!invitationCodeSecondCharRegex.test(secondChar)) {
            return content.invitationCodeSecondCharErrorMessageHtml;
        }

        if (!invitationCodeRegex.test(value)) {
            return content.invitationCodeFormatErrorMessage;
        }

        return true;
    };

    return (
        <Container>
            <StyledForm
                id="invitation-code-form"
                data-testid="invitation-code-form"
                onSubmit={handleSubmit(onSubmitWithFindOffer, onErrorWithLogging)}
            >
                <SentenceLabel htmlFor="invitationCode">
                    Please enter the 10-character invitation code found on the offer you received in the mail:
                </SentenceLabel>
                <HelpTextContainer>
                    <Popover displayText="Where's my invitation code?">
                        <Container>
                            <InvitationImage src={content.formThumbnailImageUrl}></InvitationImage>
                            <InvitationTooltip>{content.invitationCodeHelpText}</InvitationTooltip>
                        </Container>
                    </Popover>
                </HelpTextContainer>
                <InputGroup>
                    <PaddedContainer>
                        <Label htmlFor="invitationCode">10-character invitation code</Label>
                    </PaddedContainer>
                    <StyledInputInvitationCode
                        id="invitationCode"
                        hasError={!!errors?.invitationCode}
                        {...register("invitationCode", {
                            required: content.invitationCodeRequiredErrorMessage,
                            minLength: { value: 10, message: content.invitationCodeMinLengthErrorMessage },
                            maxLength: { value: 10, message: content.invitationCodeMinLengthErrorMessage },
                            validate: (value) => validateInvitationCode(value),
                        })}
                    />
                    <FieldError error={errors?.invitationCode} defaultMessage={""} renderMessageAsHtml={true} />
                    <div {...setRawHtml(content.invitationCodeHelpfulHintHtml)} />
                </InputGroup>

                {Object.keys(errors).length > 0 && (
                    <ErrorMessage text={errors?.root?.invitationCode && content.invitationNotFoundErrorMessage} />
                )}
            </StyledForm>
        </Container>
    );
};

export default InvitationCodeForm;
