import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "layout";
import Button from "components/inputs/button/Button";
import PersonalInfoForm from "features/forms/personal-info-form/PersonalInfoForm";
import callCheckIdentity from "./callCheckIdentity";
import { emptyToUndefined } from "../../../../utils/conversions";

const LoyaltyPersonalInfoContainer = ({ content, applicationData, dispatch, onError, handleClickBack, handleClickNext }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // This will likely change in the future to be a different API call than the one in DirectTermPersonalInfoContainer
    const handleSubmit = async (submittedData) => {
        try {
            setIsLoading(true);

            const { policyNumber, hannoverId } = applicationData;
            const verifiedPersonalInfoData = await callCheckIdentity("loyalty", submittedData, policyNumber, hannoverId);

            dispatch({
                type: "PERSONAL_INFO_VERIFIED",
                policyNumber: verifiedPersonalInfoData.policyNumber,

                firstName: verifiedPersonalInfoData.firstName,
                middleInitial: emptyToUndefined(verifiedPersonalInfoData.middleInitial),
                lastName: verifiedPersonalInfoData.lastName,

                addressLine1: verifiedPersonalInfoData.addressLine1,
                addressLine2: emptyToUndefined(verifiedPersonalInfoData.addressLine2),
                city: verifiedPersonalInfoData.city,
                state: verifiedPersonalInfoData.state,
                phone: verifiedPersonalInfoData.phone,
                phoneType: verifiedPersonalInfoData.phoneType,
                email: verifiedPersonalInfoData.email,
            });

            setIsLoading(false);

            handleClickNext();
        } catch (error) {
            setIsLoading(false);

            console.error(error);
            if (onError) {
                onError(error);
            }
        }
    };

    const initialValues = {
        applicantType: applicationData.applicantType,
        coverageTier: applicationData.selectedCoverageTier,
        coverageType: applicationData.selectedCoverageType,
        coveragePremium: applicationData.selectedCoveragePremium,
        firstName: applicationData.firstName,
        middleInitial: applicationData.middleInitial,
        lastName: applicationData.lastName,
        dateOfBirth: applicationData.dateOfBirth,
        zipCode: applicationData.zipCode,
        addressLine1: applicationData.addressLine1,
        addressLine2: applicationData.addressLine2,
        city: applicationData.city,
        state: applicationData.state,
        phone: applicationData.phone,
        phoneType: applicationData.phoneType,
        email: applicationData.email,
    };

    return (
        <>
            <PersonalInfoForm
                content={content}
                onSubmit={handleSubmit}
                onError={onError}
                onNavigate={navigate}
                initialValues={initialValues}
                showHeightSection={false}
                showWeightSection={false}
                showWillReplacePolicyQuestion={false}
            />
            <ButtonGroup>
                <Button label={content.previousLabel} onClick={handleClickBack} />
                <Button label={content.nextLabel} type="submit" color="primary" form="personal-info-form" loading={isLoading} />
            </ButtonGroup>
        </>
    );
};

export default LoyaltyPersonalInfoContainer;
